

let backendHost;
let socketHost;

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const hostname = window && window.location && window.location.hostname;
 if(hostname === 'backft.cianlogic.com') {
  backendHost = 'https://ftapi.cianlogic.com';
  
}else if(hostname === 'localhost') {
  
  backendHost = 'https://ftapi.cianlogic.com';

}


export const APIURL = `${backendHost}`;
export const SOCKETURL = `${socketHost}`;
export const TZ = `timeZone=${currentTimezone}`;




