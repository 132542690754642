import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AlertTemplate from "react-alert-template-basic";

import { transitions, positions,types, Provider as AlertProvider } from 'react-alert';

import "@fortawesome/fontawesome-free/css/all.min.css";
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 4000,
  offset: '20px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  types:types.SUCCESS,
   
}
ReactDOM.render(
  <React.StrictMode>
     <AlertProvider containerStyle={{fontSize:"12px",zIndex:"10000000000"}} template={AlertTemplate} {...options}>
  
    
    
    <App />
 
   
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
