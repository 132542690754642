import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link,useParams,useLocation,useNavigate  } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import Pagination from "react-js-pagination";
import "./regdhospital.css"
import "./pagination.css";

import LoadingOverlay from 'react-loading-overlay';
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import BackButton from "../common/BackButton";

function DeregisteredHospitals() {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage,] = useState(10);const [approve, setApproved] = useState(false)
const navigate = useNavigate();
  const {status} = useParams();
 console.log(status)
  const { state } = useLocation()
 
 console.log(state)

  useEffect(() => {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    axios.get(`${APIURL}/api/v1/staff/hospital-deregistered/` , {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          const data = res.data.message
          setPending(data)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

  }, [approve])


  const handleChangeRadio = (val) => {
    setApproved(val)
  }

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1)
    setSearchTerm(e.target.value)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  


  let data = pending.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  })
    .filter((value) => {
      if (searchTerm === '') {
        return value
      } else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = []
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i)
  }
  const totalPages = pageNumbers.length;

  let display = current.length > 0 ? current.map((data, index) => {
    let id = data.id

    return (

      <>  <Link style={{textDecoration:"none"}} to={{
        pathname: `/hospital-details/${id}`
      }}>
  <div className='list-item'>

    {" "}
    <h4 className="each-list-link">
    
        {(currentPage - 1) * 10 + index + 1}.{data.name}{" "}
        <i style={{float:"right"}} class="fa fa-arrow-right" aria-hidden="true"></i>
    
    </h4>
    <small>ID : {data.id} </small>

  </div>  </Link>

</> )
  }) : null

  return (
 
      <div>
        {/* <BackofficeNavBar /> */}
        <>   <LoadingOverlay
      active={loading}
      spinner
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: 'rgba(0, 0, 255, 0.5)'
            
          }
        }),
        overlay: {},
      }}
    
    >
      
          <div className="container">
    

            <div className="container failedlist-parentcontainer">
              <h2 className="title-of-page">Service Providers List</h2>
              <div className="search-section">

             
                <div className="search-div">

                  <div style={{ display: "flex",marginLeft: "25%" }}>

                    <input
                      className="form-control search-input"
                      type="text"
                      placeholder="Search Here"
                      value={searchTerm}
                      maxLength="30"
                      onChange={handleSearchChange}
                    />    
                    <button className="btn btn-primary btn-col search-btn">
                      {" "}
                      <i className="fas fa-search"></i>{" "}
                    </button>
                    <button className="btn btn-primary btn-col" style={{ marginLeft: "30%" }} onClick={() => navigate("/hospitallist")}>
                                Service Providers <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button><br/>

                  </div>
                </div>
              </div>
            
              <br />
              <div className="title-of-tasks" ><h4>Requested for Deregistration</h4>
            {/* <ToggleButtonGroup  type="radio" name="options" value={approve} onChange={handleChangeRadio}>
              <ToggleButton id="tbg-radio-1"  value={false} className="btn btn-light toggle-btns toggle-btn-1" >
                Pending deregisteration
              </ToggleButton>
              <ToggleButton id="tbg-radio-2" value={true} className="btn btn-light toggle-btns toggle-btn-end" >
                Deregistered
              </ToggleButton>
            </ToggleButtonGroup> */}

            </div>
              <div className="failed-list-section">
                <div className="container">
                  <div className="test-reports-display-list">
                    {display ? (
                      display
                    ) : (
                      <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "10%" }}>
                        {!loading?<h4>No more Deregistration Request!!</h4>:""}
                      </div>
                    )}
                  </div>

                </div>
              </div>

              {totalPages > 1 ?
                <div className="pagn pagn-small">
                  <br />
                  <br />
                  <br />
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={totalPages}
                    onChange={handlePageChange}
                    disabledClass="disabled-class"
                  />
                </div>
                : null}

            </div>
          </div>
          </LoadingOverlay>
        </>

      </div>
    
  )
}

export default DeregisteredHospitals
