import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist.css";

import EditConsumable from './EditConsumable';
import AddConsumable from "./AddConsumable";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "react-js-pagination";

function ConsumableList() {
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const [current, setCurrent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [addRentalShow, setAddRentalShow] = useState(false);
  const [category, setCategory] = useState(2);
  const [expand, setExpand] = useState();
  const [flag, setFlag] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    bindConsumable();
  }, [refresh]);

  const bindConsumable = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/staff/master-consumables/?${TZ}`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          setLoading(false);
          const datas = res.data;

          setFeatureList(datas);
          //console.log(featureList)
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeletePopUp = (id) => {
    //  e.preventDefault();
    setDeleteIndex(id);
    setDeleteShow(true);
  }

  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    //  const dataToSend = {
    //   "id" : item
    // }

    axios
      .delete(`${APIURL}/api/v1/staff/master-consumables-detail/${deleteIndex}/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 204) {
          setSuccessShow(true);

          // setRefresh(!refresh)
        } else {
          console.log("else called ");
          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      });
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
         

          <Button
            variant="danger"
            onClick={() => {
              handleDelete(deleteIndex);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopUp = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success">Consumable Deleted Successfully!!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const modalClose = () => {
    setEditShow(false);
    setRefresh(!refresh);
  };

  const rentalModalClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setFlag(true);
  };

  const notProceedClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
  };

  const handleClosePopup = () => {
    bindConsumable();
    setEditShow(false);
    setAddRentalShow(false);
  };

  const AddRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddConsumable
          data={featureList}
            modalClose={rentalModalClose}
            notProceedClose={notProceedClose}
          />
        </Modal.Body>

      </Modal>
    );
  };

  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditConsumable data={current} modalClose={modalClose} />
        </Modal.Body>
       
        
      </Modal>
    );
  };

  const handleEdit = (item) => {
    // const list = featureList[index]
    setCurrent(item)
    setEditShow(true)
  }

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let data = featureList
    .sort((a, b) => {
      if (a.consumable_name.toLowerCase() < b.consumable_name.toLowerCase())
        return -1;
      if (a.consumable_name.toLowerCase() > b.consumable_name.toLowerCase())
        return 1;
      return 0;
    })
    .filter((value) => {
      if (searchTerm === "") {
        return value;
      } else if (
        value.consumable_name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return value;
      }
    });

  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;
  let dataDisplay = [];

  dataDisplay =
    category === 2 ? (
      featureList.length > 0 ? (
        current1.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleDetail(index)}
            >
              <div className="feature-data">
                <h5 className="feature-title">
                  <b>
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDetail(index)}
                    >
                      {(currentPage - 1) * 10 + index + 1}.
                      {item.consumable_name}<span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span><span style={{ fontSize: "20px", color: "green" }}>(Type:{item.type})</span>
                    </span><span className='attrstyle'>
                    {item.color!==""&&item.color!==null&&item.color!=="null"?<><i style={{color:item.color, border:"1px solid black",borderRadius:"50%"}} class="fa fa-circle" aria-hidden="true"></i><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.size!==""&&item.size!==null&&item.size!=="null"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.size}</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.weight!==""&&item.weight!==null&&item.weight!=="null"&&item.weight!=="0"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.weight}Kg</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.volume!==""&&item.volume!==null&&item.volume!=="null"&&item.volume!=="0"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.volume}L</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}</span>
                    <i
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </b>

                  <i class="fas fa-trash-alt"
                    //onClick={() => handleDeletePopUp(item.id)} 
                    style={{ float: "right", color: "red", opacity: "0.5", cursor: "not-allowed" }} ></i>
                  <i class="fas fa-pencil-alt" onClick={() => handleEdit(item)} aria-hidden="true" style={{ float: "right", paddingRight: "25px", color: "#5a9569", cursor: 'pointer' }}></i>
                </h5>
              </div>

              {expand === index ? (
                <>
                  <div className="seperate-content" />
                  <div  className="prod-div">
                    <div className=" ">
                      <img src={item.image} style={{marginLeft:"30%",border:"1px solid rgba(0,0,0,0.2)", padding:"3px"}} alt="Test image" width="100" height="100" />
                      <div className="row"style={{marginTop:"2%", fontSize:"12px"}}>
                        <div className="col-6"style={{}} >
                          <span><b>Consumable Name : {item.consumable_name}</b></span><br/>
                          <span><b>Type : {item.type}</b></span><br/>
                          <span><b>manufacturer : {item.manufacturer}</b></span><br/>
                          <span><b>brand : {item.brand}</b></span><br/>
                          <span><b>model : {item.model}</b></span><br/>
                        </div>
                        <div className="col-6">

                        <span><b>color : {item.color}</b></span><br/>
                        <span><b>weight : {item.weight}Kg</b></span><br/>
                        <span><b>size : {item.size}</b></span><br/>
                        <span><b>volume : {item.volume}L</b></span><br/>
                        <span><b>Description : {item.description}</b></span><br/>
                        </div>
                      </div>


                    </div>
                  </div>
                  {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                </>
              ) : null}
            </div>
          );
        })
      ) : (
        <div
          style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "40px",
          }}
        >
          {!loading ? <h4>No Consumables to show!!</h4> : " "}
        </div>
      )
    ) : null;
  const handleBack = (e) => {
    navigate(-1);
  };

  return (
    <>
      {/* <BackofficeNavBar/> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="container displaylist-container ">
          {/* <button style={{padding:"5%",marginTop:"5%"}}
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button> */}

          <div className="header-display">
            <h2 className="title-of-page"> Consumable List</h2>

            <div className="search-section">
              <div className="search-div">
                <div style={{ display: "flex", marginLeft: "28%" }}>
                  <input
                    className="form-control search-input"
                    type="text"
                    placeholder="Search Here"
                    value={searchTerm}
                    maxLength="30"
                    onChange={handleSearchChange}

                  />
                  <button className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </button>
                  <div className="buttons-wrapper">
                    {category === 2 ? (
                      <button
                        className="btn btn-primary btn-col"
                        style={{ marginLeft: "60%" }}
                        onClick={() => setAddRentalShow(true)}
                      >
                        Add Consumable <i class="fas fa-plus"></i>
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="test-reports-display-list">
            <div className="feature-container">{dataDisplay}</div>
          </div>
          {totalPages > 1 ? (
            <div className="pagn pagn-small">
              <br />
              <br />
              <br />
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                disabledClass="disabled-class"
              />
            </div>
          ) : null}

          {editShow ? (
            <EditPopUp show={editShow} onHide={() => setEditShow(false)} />
          ) : null}
          {deleteShow ? (
            <DeleteConfirmPopup
              show={deleteShow}
              onHide={() => {
                setDeleteShow(false);
                setDeleteIndex("");
              }}
            />
          ) : (
            ""
          )}

          {successShow ? (
            <SubmitPopUp
              show={successShow}
              onHide={() => {
                setSuccessShow(false);
                setRefresh(!refresh);
              }}
            />
          ) : null}

          {addRentalShow ? (
            <AddRentalPopUp
              show={addRentalShow}
              onHide={() => setAddRentalShow(false)}
            />
          ) : null}
        </div>
      </LoadingOverlay>
    </>
  );
}

export default ConsumableList;
