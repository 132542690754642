import React,{useState,useEffect,useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

import { useAlert } from "react-alert";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
function AddScan(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
    const[data, setData]= useState({


  
      scanname:'',
      scantype:'',
      scanmethod:'',
      scansarea:'',
      group:{ value:'', label:'' },
      cptcode:'', 
     // agegroup:{value:'',label:''},  
      description:'',
    
      country : { value:'Malta', label:'Malta' }
    })

    const [error,setError]=useState({
      scanname:'',
      scantype:'',
     // agegroup:'',
     group:'',
   
    country : ''
        
       });
       const group3 = [{ value:'male', label:'MALE' },
       { value:'female', label:'FEMALE' },
   { value:'others', label:'OTHERS' },
      
   ]


   const agegroupvalue = [{ value:'1', label:'Infants' },
   { value:'2', label:'kids' },{value:'3', label:'teen'},{value:'4', label:'young'},{value:'5', label:'adult'},{value:'6', label:'elderly'}
  
]
const alert = useAlert();
let formData =new FormData();
const [image, setImage] = useState(null);
    const [successShow, setSuccessShow] = useState(false)
    const[errorValidate,setErrorValidate]=useState(false)
    const[ageGroupData,setAgeGroupData] = useState("")



useEffect(() => {
  const tokenString = sessionStorage.getItem("usertoken");
  let str = tokenString.replace(/["]+/g, "");
  axios
    .get(`${APIURL}/api/v1/staff/age-groups/?${TZ}`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        setAgeGroupData(res.data);
      }
    })
}, []);

const handleImageUpload = (event) => {
  const selectedImage = event.target.files[0];
  setImage(selectedImage);
}
  
    
    
  
    
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleConfirm = () => {
        closeConfirmationPopup()
        saveChanges();
       
      };
      
      const handleCancel = () => {
        closeConfirmationPopup()
      };
      const handleSave=() => {
        if(validate()){ 
          setShowConfirmation(true)
        } else {
          setErrorValidate(true)
        }
      }

      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , group: data
        }))
   }

   const handleAgeGroup= (data) => {

    setData( current => ({
        ...current , agegroup: data
    }))
  }

 
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});
  
        
    if (!input["scanname"].trim() || input["scanname"].trim() ==="" ) {
      isValid = false;
      errors["scanname"] = "Enter Scan Name";
    }
    if (!input["scansarea"].trim() || input["scansarea"].trim() ==="" ) {
      isValid = false;
      errors["scansarea"] = "Enter Scan Area";
    }

  

    if (!input["scantype"] || input["scantype"]==="" ) {
      isValid = false;
      errors["scantype"] = "Enter Scan Type";
    }
  
   
    if (!input.group || (input.group && !input.group.value) ) {
      isValid = false;
      errors["group"] = "Select group";
    }
  
    if (!input["country"] || input["country"]==="" ) {
      isValid = false;
      errors["country"] = "Please Select country";
    }
 
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = ()=> {
 
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     );
  const dataToSend = {
    scan_name : data.scanname.slice(0,1).toUpperCase() +data.scanname.slice(1, data.scanname.length), 
    scan_type : data.scantype,
    scan_method : data.scanmethod,
    scan_area :  data.scansarea,                                
   cpt_code: data.cptcode,
    country :       data.country.value,           
    group : data.group.value,
   // age_group :data.agegroup.value,
    description:data.description

  }

  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));
 
     axios
       .post(`${APIURL}/api/v1/staff/master-scans/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status==="success") {
          alert.success('Successfully saved!')
          props.modalClose();
          setData({
            scanname:'',
      scantype:'',
      scanmethod:'',
      scansarea:'',
     group:{ value:'', label:'' },
     cptcode:'',   
    description:'',
    country : { value:'', label:'' }
         });}
          else {
         alert.error(res.data.message)
         }
       })
       .catch((err) => {
         //setErrorShow(true)
       });
       setSuccessShow(false)
   

}

    return (
      <>
        <h2 style={{marginTop:'0'}} className="title-of-page">Add Scan</h2>
        <div style={{display:"flex", marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px" }}>
          <img src={URL.createObjectURL(image)} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
          <div style={{maxHeight:'1000px', width:"90%"}} className='config-box fit-content'> 
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Scan Name: </span>
                  <input type='text' className='form-control title-flex' name='scanname' value={data.scanname} onChange={handleTitle} />
                  {error.scanname ? <div className="error-validation-msg error-feature-inventory">{error.scanname}</div> : null}
                </div>

                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Scan Type : </span>
                  <input type='text' className='form-control title-flex' name='scantype' value={data.scantype} onChange={handleTitle} />
                  {error.scantype ? <div className="error-validation-msg error-feature-inventory">{error.scantype}</div> : null}
                </div>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'>Scan Method : </span>
                  <input type='text' className='form-control title-flex' name='scanmethod' value={data.scanmethod} onChange={handleTitle} />
                  {error.scanmethod ? <div className="error-validation-msg error-feature-inventory">{error.scanmethod}</div> : null}
                
                </div>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'> *Scan Area: </span>
                  <input type='text' className='form-control title-flex' name='scansarea' value={data.scansarea} onChange={handleTitle} />
                  {error.scansarea ? <div className="error-validation-msg error-feature-inventory">{error.scansarea}</div> : null}
                </div>
                

                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Group: </span>
                  <Select
                    value={data.group}
                    onChange={(data,e)=>{handleServiceChange(data,e)
                    }}
                    options={group3}
                    name='group'
                    className="select-currency select-flex title-flex"
                  />
                  {error.group ? <div className="error-validation-msg error-feature-inventory">{error.group}</div> : null}
                </div>
               
            </div>
            <div className='text-center'>
              <button onClick={handleSave} className='btn btn-primary btn-col mb-2'>Save</button>  
            </div>
            {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}          
          </div>
      </>
  )
}

export default AddScan;
