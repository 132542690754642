import { APIURL,TZ } from "./Global";
import axios from "axios";
import img from "./assets/images/felixa_logo.png";
import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./modules/login/LoginPage";
import ForgotPassword from "./modules/login/ForgotPassword";
import InputOtp from "./modules/login/InputOtp";
import LogoutPage from "./modules/login/LogoutPage";
import HomePage from "./modules/home/HomePage";
import StaffDashboard from "./modules/staff/StaffDashboard";
import Navbar from "./modules/common/Navbar";



import Footer from "./modules/common/Footer";
import RequireAuth from "./modules/registration/RequireAuth"
import { LoginProvider } from "./modules/contexts/LoginContext";
import { LoginStatusProvider } from "./modules/contexts/LoginStatusContext";
import { HospLoginProvider } from "./modules/contexts/HospitalLoginContext";
import { RegProvider } from "./modules/contexts/RegistrationContext";


import PageNotFound from "./modules/errorPage/PageNotFound";


import RegisteredHospitals from "./modules/staff/RegisteredHospitals";
import DeregisteredHospitals from "./modules/staff/DeregisteredHospitals";
import HospitalDetails from "./modules/staff/HospitalDetails";
import ServiceDetails from "./modules/staff/service/ServiceDetails"

import "react-datepicker/dist/react-datepicker.css";
import { ProfileProvider } from "./modules/contexts/ProfileContext";


import { ConfirmationProvider } from "./modules/contexts/SubmitConfirmationContext"

import MainServices from "./modules/staff/service/MainServices";
import Approvalpage from "./modules/staff/ApprovalImage/Approvalpage";

import Scan from "./modules/staff/AddScanTest/Scan";
import Test from "./modules/staff/AddLabTest/Test";
import ServiceOrderList from "./modules/staff/service/ServiceOrdersList";
import ServiceOrderReqDetails from "./modules/staff/service/ServiceOrderReqDetails";
import Configuration from "./modules/staff/Configuration/Configuration";



import ObservationSymptomsBO from "./modules/staff/ObservationAndSymptoms/ObservationSympotmsBO";
import Allergy from "./modules/staff/AddAllergy/Allergy";
import Misc from "./modules/staff/AddMisc/Misc";
import PharmMedicine from "./modules/staff/AddMedicines/PharmMedicine";
import PrimaryDiscease from "./modules/staff/AddPrimaryDiscease/PrimaryDiscease";

import Comorbidites from "./modules/staff/AddComobridities/Comorbidites"
import ProcedureList from "./modules/staff/ProcedureList/ProcedureList";


import Profile from "./modules/common/Profile/Profile"


import ConsumableList from "./modules/staff/Consumable/ConsumableList";

import AppUserDash from "./modules/ElderCare/AppUserDash";


function App() {
 

  useEffect(() => {

    axios
      .get(`${APIURL}/api/v1/account/customer-info/?${TZ}`, {
        headers: {},
      })

      .then((res) => {

        if (res.data.status === "success") {
          sessionStorage.setItem("project_type", res.data.project_type);
            document.getElementById("favicon").setAttribute("href", res.data.app_logo)
            document.getElementById('title').innerHTML = res.data.message.app_name ? res.data.message.app_name : "Felixa Care";
        }
        else {
          document.getElementById("favicon").setAttribute("href", img)
        }
      })
  }, []);

  return (
    <>

      <BrowserRouter>
      <ConfirmationProvider>
        <RegProvider>
          <LoginStatusProvider>
            <HospLoginProvider>
              <ProfileProvider>
                <LoginProvider>
                 
                 <Navbar /><br/><br/><br/>
                  <div className="test">


                    <Routes >
                      
                      <Route exact path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
                       <Route exact path="/AppUser"  element={<RequireAuth type="appuser"><AppUserDash/></RequireAuth>} />
                      <Route exact path="/hospitallist" element={<RequireAuth type="config_admin"><RegisteredHospitals /></RequireAuth>} />
                      <Route exact path="/Deregistered_splist" element={<RequireAuth type="config_admin"><DeregisteredHospitals /></RequireAuth>} />
                      <Route exact path="/servicelist" element={<RequireAuth type="config_admin"><MainServices /></RequireAuth>} />
                      <Route exact path="/approvalpage" element={<RequireAuth type="config_admin"><Approvalpage /></RequireAuth>} />
                      
                      <Route exact path="/hospital-details/:id" element={<RequireAuth type="config_admin"><HospitalDetails /></RequireAuth>} />
                      <Route exact path="/service-details/:id" element={<RequireAuth type="config_admin"><ServiceDetails /></RequireAuth>} />
                      <Route exact path="/login" element={<LoginPage />} />
                   
                      <Route exact path="/logout" element={<LogoutPage />} />
       
                     
                     <Route exact path="/config_admin" element={<RequireAuth type="config_admin"><StaffDashboard /></RequireAuth>} />
                      <Route exact path="/404" element={<PageNotFound />} />
                     
                       <Route exact path="/doctor/profile" element={<Profile />} />
                  
                     
                      <Route exact path="/configuration" element={<RequireAuth type="config_admin"><Configuration /></RequireAuth>} />
                     
                     <Route exact path="/forgot-password" element={<ForgotPassword />} />
                     

                     <Route exact path="/Scantest" element={<RequireAuth type="config_admin"><Scan /></RequireAuth>} />
                      <Route exact path="/Labtest" element={<RequireAuth type="config_admin"><Test /></RequireAuth>} />
                      <Route exact path="/observation-symptoms" element={<RequireAuth type="config_admin"><ObservationSymptomsBO /></RequireAuth>} />
                      <Route exact path="/allergy" element={<RequireAuth type="config_admin"><Allergy /></RequireAuth>} />
                      <Route exact path="/misc" element={<RequireAuth type="config_admin"><Misc /></RequireAuth>} />
                      <Route exact path="/medicine" element={<RequireAuth type="pharmacy"><PharmMedicine /></RequireAuth>} />
                      <Route exact path="/primary-disease" element={<RequireAuth type="config_admin"><PrimaryDiscease /></RequireAuth>} />
                      <Route exact path="/comorbidities" element={<RequireAuth type="config_admin"><Comorbidites /></RequireAuth>} />
                      <Route exact path="/procedures" element={<RequireAuth type="config_admin"><ProcedureList /></RequireAuth>} />
                      <Route exact path="/consumablelist" element={<RequireAuth type="config_admin"><ConsumableList /></RequireAuth>} />
                     
                      <Route exact path="/serviceorderlist" element={<RequireAuth type="config_admin"><ServiceOrderList /></RequireAuth>} />
                      <Route exact path="/serviceorderdetails/:id" element={<RequireAuth type="config_admin"><ServiceOrderReqDetails /></RequireAuth>} />
                    
                    
                    </Routes>
                  </div>
                  <Footer />
                 
                </LoginProvider>
              </ProfileProvider>
            </HospLoginProvider>

          </LoginStatusProvider>
        </RegProvider>
</ConfirmationProvider>
      </BrowserRouter>


    </>
  );
}

export default App;
