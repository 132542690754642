
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';
 


function AppUserDash() {
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <>
    <div>
      {/* <HospDocNavbar/> */}
    </div>
    <div >

      <div className="home">
        {/* <h1>Doctor Dashboard</h1> */}
        <h3 className="title-of-page"> Welcome to Your Dashboard</h3>
        
        <h5 className="subtitle-of-page sda-dashboard">We are in process of implementing Web app with all functionalities. Please use our mobile app to use our services till then.
        <br/> For more information please contact our sales team @<a
          aria-label="mail id"
          href={"mailto:'felixasales@intpurple.com'"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i style={{width:"17%",fontSize:"20px"}}className="fas fa fa-envelope social-media-icons">felixasales@intpurple.com</i>
        </a>
        <br/> Thanks for understanding  </h5>

        <div className="home-container">
         

          


          

          
        </div>

      </div>

    </div>

    </>


  );
}

export default AppUserDash;