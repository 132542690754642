import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Modal } from "react-bootstrap";
import AddService from "./AddService";
import EditService from "./EditService";

import LoadingOverlay from "react-loading-overlay";
import BackButton from "../../common/BackButton";
function MainServices() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [addServiceShow, setAddServiceShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [editShow, setEditShow] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [consentLevel, setConsentLevel] = useState("");

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Delete Master Service!! </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to Delete the Service {serviceName}
          </h5>
          <br />

          <div className="form-group "  >
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleDelete(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios
      .delete(`${APIURL}/api/v1/staff/service-list-detail/${deleteIndex}/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 204) {
          loadData();
        } else {
          console.log("else called ");
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
      });
  };

  const loadData = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/staff/service-list/?${TZ}`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          const datas = res.data;

          setServiceList(datas);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSuccess = () => {
    loadData();
    setAddServiceShow(false);
    setEditShow(false);
  };

  const AddServicePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-medium"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddService onSuccess={onSuccess} />
        </Modal.Body>
      </Modal>
    );
  };
  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-medium"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditService
            onSuccess={onSuccess}
            service_name1={serviceName}
            id={editIndex}
            consent_Level1={consentLevel}
          />
        </Modal.Body>
      </Modal>
    );
  };
  const handleBack = (e) => {
    navigate(-1);
  };
  const handleDeletePopUp = (id, name) => {
    setDeleteIndex(id);
    setServiceName(name);
    setDeleteShow(true);
  };
  const handleEditPopUp = (id, name, consent) => {
    setEditIndex(id);
    setEditShow(true);
    setServiceName(name);
    setConsentLevel(consent);
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let data = serviceList
    ? serviceList
        .sort((a, b) => {
          if (a.service_name.toLowerCase() < b.service_name.toLowerCase())
            return -1;
          if (a.service_name.toLowerCase() > b.service_name.toLowerCase())
            return 1;
          return 0;
        })
        .filter((value) => {
          if (searchTerm === "") {
            return value;
          } else if (
            value.service_name.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return value;
          }
        })
    : [];

  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;

  let display =
    current.length > 0
      ? current.map((data, index) => {
          return (
            <div className="list-item">
              {" "}
              <h4 className="each-list-link">
            
                <Link
                  to={{
                    pathname: `/service-details/${data.id}`,
                  }} state={{category: data.service_category}}
                >
                  {(currentPage - 1) * 10 + index + 1}.{data.service_name}{" "}
                </Link>
                {/* <i
                  class="fas fa-trash-alt"
                  onClick={() => handleDeletePopUp(data.id, data.service_name)}
                  style={{ float: "right", color: "red" }}
                ></i> */}
                <i
                  class="fas fa-pencil-alt"
                  onClick={() =>
                    handleEditPopUp(
                      data.id,
                      data.service_name,
                      data.consent_level
                    )
                  }
                  aria-hidden="true"
                  style={{
                    float: "right",
                    paddingRight: "25px",
                    color: "#5a9569",
                  }}
                ></i>
              </h4>
            </div>
          );
        })
      : null;

  return (
    <>
      {/* <div>
            <BackofficeNavBar/>
        </div> */}
      <LoadingOverlay
        active={loading}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div>
          <>
            {/*   */}
            <div className="container">
              <div className="container failedlist-parentcontainer">
                <h2 className="title-of-page">Master Services List</h2>

                <div className="search-section">
                  <div className="search-div">
                    <div style={{ display: "flex",marginLeft: "20%"  }}>
                      <input
                        style={{}}
                        className="form-control search-input"
                        type="text"
                        placeholder="Search a Service"
                        value={searchTerm}
                        maxLength="150"
                        onChange={handleSearchChange}
                      />
                      <button className="btn btn-primary btn-col search-btn">
                        {" "}
                        <i className="fas fa-search"></i>{" "}
                      </button>
                      <button
                        className="btn btn-primary btn-col"
                        style={{ marginLeft: "30%" }}
                        onClick={() => setAddServiceShow(true)}
                      >
                        Add Service <i className="fa fa-plus"></i>
                      </button>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="failed-list-section">
                  <div className="container">
                    <div className="test-reports-display-list">
                      {display ? (
                        display
                      ) : (
                        <div
                          style={{
                            color: "red",
                            fontSize: "25px",
                            height: "200px",
                            padding: "40px",
                          }}
                        >
                          {!loading ? <h4>No list to show!!</h4> : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {totalPages > 1 ? (
                  <div className="pagn pagn-small">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={totalPages}
                      onChange={handlePageChange}
                      disabledClass="disabled-class"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </>
        </div>
      </LoadingOverlay>
      {addServiceShow ? (
        <AddServicePopUp
          show={addServiceShow}
          onHide={() => setAddServiceShow(false)}
        />
      ) : null}

      {deleteShow ? (
        <DeleteConfirmPopup
          show={deleteShow}
          onHide={() => {
            setDeleteShow(false);
            setDeleteIndex("");
          }}
        />
      ) : (
        ""
      )}
      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
            setEditIndex("");
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default MainServices;
